import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { useOktaAuth } from '@okta/okta-react';

import {
  Window,
  WindowContent,
  WindowHeader,
  Button,
  Panel,
} from 'react95';

const Wrapper = styled.div`
    padding: 5rem;
    .window-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .close-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: -1px;
      margin-top: -1px;
      transform: rotateZ(45deg);
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        background: ___CSS_0___;
      }
      &:before {
        height: 100%;
        width: 3px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:after {
        height: 3px;
        width: 100%;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .window {
      width: 400px;
      min-height: 200px;
    }
    .footer {
      display: block;
      margin: 0.25rem;
      height: 31px;
      line-height: 31px;
      padding-left: 0.25rem;
    }
  `;

const LoginWindow = (props) => {
  const { oktaAuth } = useOktaAuth();
  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
  };

  const { error } = props;

  return (
    <Wrapper>
      <Window resizable className="window">
        <WindowHeader className="window-header">
          <span>Login to Space Command</span>
        </WindowHeader>
        <Panel variant="well" style={{ textAlign: 'center', width: '100%' }}>
          CONFIDENTIAL: Authorized Access Only
        </Panel>
        { error && (
          <Panel variant="well" style={{ color: 'red', textAlign: 'center', width: '100%' }}>
            <p>
              You are not authorized access to this application from your device.
            </p>
            <p>
              Please contact your administrator for assistance.
            </p>
          </Panel>
        )}
        <WindowContent>
          <div style={{ textAlign: 'center' }}>
            <Image size="small" src="/space-command-space-force.png" centered />
            <p>
              This is USA Space Force Space Command.
            </p>
            <p>
              Login to continue.
            </p>
          </div>
          <Button id="login-button" primary onClick={login}>Login</Button>
        </WindowContent>
        <Panel variant="well" style={{ textAlign: 'center', width: '100%' }} className="footer">
          CONFIDENTIAL: Authorized Access Only
        </Panel>
      </Window>
    </Wrapper>
  );
};

export default LoginWindow;
