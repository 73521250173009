import React from 'react';
import { Window, WindowHeader } from 'react95';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

import config from './config';

const Wrapper = styled.div`
    padding: 5rem;
    .window-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .close-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: -1px;
      margin-top: -1px;
      transform: rotateZ(45deg);
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        background: ___CSS_0___;
      }
      &:before {
        height: 100%;
        width: 3px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:after {
        height: 3px;
        width: 100%;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .window {
      width: 910px;
      min-height: 640px;
    }
    .footer {
      display: block;
      margin: 0.25rem;
      height: 31px;
      line-height: 31px;
      padding-left: 0.25rem;
    }
  `;

const Paint = () => {
  const { paintUrl } = config;
  console.log(paintUrl);

  return (
    <Wrapper>
      <Window resizeable className="window window-main">
        <WindowHeader className="window-header">
          <Image src="./Paint.ico" height="16px" width="16px" />
        </WindowHeader>
        <iframe title="paint" src={paintUrl} height="640px" width="900px" />
      </Window>
    </Wrapper>
  );
};

export default Paint;
