import React from 'react';
import {
  List,
  ListItem,
  Window,
} from 'react95';

const ErrorList = async (props) => {
  if (props === null) {
    return <> </>;
  }
  const { header, list } = props;
  const listItems = list.map((item) => <ListItem key={item.key}>{item.message}</ListItem>);
  return (
    <Window>
      <p>{header}</p>
      <List>{listItems}</List>
    </Window>
  );
};

export default ErrorList;
