import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import styled from 'styled-components';
import {
  Window,
  WindowContent,
  WindowHeader,
  Button,
  Panel,
  LoadingIndicator,
} from 'react95';

const Wrapper = styled.div`
  background: ___CSS_0___;
  padding: 5rem;
  .window-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -1px;
    margin-top: -1px;
    transform: rotateZ(45deg);
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      background: ___CSS_0___;
    }
    &:before {
      height: 100%;
      width: 3px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 3px;
      width: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .window {
    width: 400px;
    min-height: 200px;
  }
  .footer {
    display: block;
    margin: 0.25rem;
    height: 31px;
    line-height: 31px;
    padding-left: 0.25rem;
  }
`;

const LoadingWindow = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <Wrapper>
      <Window resizable className="window">
        <WindowHeader className="window-header">
          <span>Login to Space Command</span>
        </WindowHeader>
        <Panel variant="well" style={{ textAlign: 'center', width: '100%' }} className="footer">
          CONFIDENTIAL: Authorized Access Only
        </Panel>
        <WindowContent>
          <p style={{ textAlign: 'center', marginBottom: '0.5rem' }}>Loading...</p>
          <LoadingIndicator isLoading />
          <Button id="login-button" primary onClick={logout}>Cancel</Button>
        </WindowContent>
        <Panel variant="well" style={{ textAlign: 'center', width: '100%' }} className="footer">
          CONFIDENTIAL: Authorized Access Only
        </Panel>
      </Window>
    </Wrapper>
  );
};

export default LoadingWindow;
